import * as React from "react"
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import ProgramsEducationGB from "../components/education/programs"

import FindDreamJob from "../components/careers/finddreamjob"
import { Row } from "react-bootstrap"


const IndexPage = () => (
  <Layout>
    <Seo title="Education" />
    <Row>

    <ProgramsEducationGB></ProgramsEducationGB>
      <FindDreamJob></FindDreamJob> 
    </Row>

  </Layout>
)

export default IndexPage
